import React, { useEffect, useState } from "react";
import "./App.css"; // Optional for styling

function App() {
    const [models, setModels] = useState([]);

    // Fetch the JSON data from your backend or static file
    useEffect(() => {
        fetch("https://lustlist.me:3001/api/models")
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => setModels(data))
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    return (
        <div className="app-container">
            <h1>Model Gallery</h1>
            <div className="model-grid">
                {models.map((model) => (
                    <div key={model.id} className="model-card">
                        {/* Display the image */}
                        <img
                            src={model.image_url || "https://via.placeholder.com/150"}
                            alt={model.username || "Unknown"}
                            className="model-image"
                        />
                        {/* Display the username */}
                        <h3>{model.username || "Unnamed Model"}</h3>
                        {/* Social media links */}
                        <div className="social-links">
                            {Object.entries(model.social_media_links).map(
                                ([platform, links]) =>
                                    links.map((link, index) => (
                                        <a
                                            key={index}
                                            href={link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="social-link"
                                        >
                                            {platform}
                                        </a>
                                    ))
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
